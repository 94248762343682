





import React, { useEffect, useRef, useState, useContext } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { MoreVert, KeyboardVoiceRounded, MicOffRounded, VideocamRounded, VideocamOffRounded, CallEndRounded } from '@mui/icons-material';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';


function AgoraVideoCall({
  userInfo,
  callingDeviceId,
  callingAccountId,
  callerName,
  onClose,
  localVideoTrack,
  localAudioTrack,
  initialCameraEnabled,
  initialMicrophoneEnabled,
  audiosource,
  videosource,
  audioOutput,

}) {
  const [joined, setJoined] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState([]);
  const [isCameraEnabled, setIsCameraEnabled] = useState(initialCameraEnabled);
  const [isMicrophoneEnabled, setIsMicrophoneEnabled] = useState(initialMicrophoneEnabled);
  const [localVideoEnabled, setLocalVideoEnabled] = useState(initialCameraEnabled);
  const clientRef = useRef(null);
  const isJoiningRef = useRef(false);
  const localUserIdRef = useRef(null);
  const localVideoRef = useRef(null);
  const [remoteCameraStates, setRemoteCameraStates] = useState({});
  const remoteVideoRefs = useRef({});
  const aspectRatio = 16 / 9;
  const [showAlert, setShowAlert] = useState(false);
  const [callState, setCallState] = useState('waiting');

  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState([]);
  const [selectedAudioInput, setSelectedAudioInput] = useState(audiosource);
  const [selectedVideo, setSelectedVideo] = useState(videosource);
  const [selectedAudioOutput, setSelectedAudioOutput] = useState(audioOutput);
  const [currentAudioTrack, setCurrentAudioTrack] = useState(localAudioTrack);
  const [currentVideoTrack, setCurrentVideoTrack] = useState(localVideoTrack);

  const [showOptionsDialog, setShowOptionsDialog] = useState(false);
  const optionsDialogRef = useRef(null);


  const switchTrack = async (deviceType, deviceId) => {
    // ... existing Track change handling logic

    try {

      if (deviceType === "audio" && deviceId !== null) {

        await clientRef.current.unpublish(currentAudioTrack);
        await currentVideoTrack.setEnabled(false);


        const newTrack = await AgoraRTC.createMicrophoneAudioTrack({ microphoneId: deviceId });
        setCurrentAudioTrack(newTrack);
      }

      if (deviceType === "video") {
        await clientRef.current.unpublish(currentVideoTrack);
        await currentVideoTrack.setEnabled(false);
        console.log("track unpublished");

        if (deviceId !== null) {
          const newTrack = await AgoraRTC.createCameraVideoTrack({ cameraId: deviceId });
          console.log(deviceId);
          setCurrentVideoTrack(newTrack);
        }
        if (deviceId === null) {
          const newTrack = await AgoraRTC.createCameraVideoTrack();
          console.log(null);
          setCurrentVideoTrack(newTrack);
        }




      }



    } catch (error) {
      console.error('Failed to switch device:', error);
    }
  };





  const handleAudioInputChange = async (event) => {
    const newDeviceId = event.target.value;
    setSelectedAudioInput(newDeviceId);
    await switchTrack("audio", newDeviceId);
  };

  const handleCameraChange = async (event) => {
    //setter(event.target.value);
    const newDeviceId = event.target.value;
    setSelectedVideo(newDeviceId);
    await switchTrack("video", newDeviceId);
  };

  const handleAudioOutputChange = async (event, setter) => {
    const newDeviceId = event.target.value;
    setter(newDeviceId);

    try {
      // Change the audio output for all remote users
      for (const user of remoteUsers) {
        if (user.audioTrack) {
          await user.audioTrack.setPlaybackDevice(newDeviceId);
        }
      }
      console.log("Audio output changed successfully");
    } catch (error) {
      console.error("Error changing audio output:", error);
    }
  };
  useEffect(() => {
    if (selectedAudioOutput) {
      remoteUsers.forEach(async (user) => {
        if (user.audioTrack) {
          try {
            await user.audioTrack.setPlaybackDevice(selectedAudioOutput);
          } catch (error) {
            console.error("Error updating audio output for user:", user.uid, error);
          }
        }
      });
    }
  }, [selectedAudioOutput, remoteUsers]);







  //  useEffect(() => {
  //     const fetchDevices = async () => {
  //       try {
  //         // Fetch and modify audio input devices (microphones)
  //         const audioDevices = await AgoraRTC.getMicrophones();
  //         const filteredAudioInputDevices = audioDevices
  //           .filter((device, index, self) =>
  //             index === self.findIndex((d) => d.groupId === device.groupId)
  //           )
  //           .map((device) => ({
  //             ...device,
  //             label: device.label.replace(/default|communications/gi, '').trim(), // Remove 'default' and 'communications'
  //           }));
  //         setAudioInputDevices(filteredAudioInputDevices);
  //         console.log(filteredAudioInputDevices);

  //         // Fetch and modify audio output devices (speakers/headphones)
  //         const audioOutput = await AgoraRTC.getPlaybackDevices();
  //         const filteredAudioOutputDevices = audioOutput
  //           .filter((device, index, self) =>
  //             index === self.findIndex((d) => d.groupId === device.groupId)
  //           )
  //           .map((device) => ({
  //             ...device,
  //             label: device.label.replace(/default|communications/gi, '').trim(), // Remove 'default' and 'communications'
  //           }));
  //         setAudioOutputDevices(filteredAudioOutputDevices);
  //         console.log(filteredAudioOutputDevices);

  //         // Fetch and modify video devices (cameras)
  //         const cameraDevices = await AgoraRTC.getCameras();
  //         const filteredCameraDevices = cameraDevices
  //           .filter((device, index, self) =>
  //             index === self.findIndex((d) => d.groupId === device.groupId)
  //           )
  //           .map((device) => ({
  //             ...device,
  //             label: device.label.replace(/default|communications/gi, '').trim(), // Remove 'default' and 'communications'
  //           }));
  //         setVideoDevices(filteredCameraDevices);
  //         console.log(filteredCameraDevices);

  //       } catch (error) {
  //         console.error('Error fetching devices:', error);
  //       }
  //     };

  //     fetchDevices();

  //     // Device change event listener
  //     const handleDeviceChange = async () => {
  //       console.log("Device change detected, refetching devices...");
  //       await fetchDevices(); 
  //     };

  //     // Add event listener for device changes
  //     navigator.mediaDevices.ondevicechange = handleDeviceChange;

  //     return () => {
  //       // Remove the event listener when the component unmounts
  //       navigator.mediaDevices.ondevicechange = null;
  //     };
  //   }, []);

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        // Fetch and modify audio input devices (microphones)
        const audioDevices = await AgoraRTC.getMicrophones();
        const filteredAudioInputDevices = audioDevices
          .filter((device, index, self) =>
            index === self.findIndex((d) => d.groupId === device.groupId)
          )
          .map((device) => ({
            deviceId: device.deviceId,  // Retain deviceId for track creation
            ...device,
            label: device.label
              .replace(/default|communications/gi, '') // Remove 'default' and 'communications'
              .replace(/-/g, '') // Remove hyphen '-'
              .trim(), // Trim extra spaces
          }));
        setAudioInputDevices(filteredAudioInputDevices);
        console.log(filteredAudioInputDevices);

        // Fetch and modify audio output devices (speakers/headphones)
        const audioOutput = await AgoraRTC.getPlaybackDevices();
        const filteredAudioOutputDevices = audioOutput
          .filter((device, index, self) =>
            index === self.findIndex((d) => d.groupId === device.groupId)
          )
          .map((device) => ({
            deviceId: device.deviceId,  // Retain deviceId for track creation
            ...device,
            label: device.label
              .replace(/default|communications/gi, '') // Remove 'default' and 'communications'
              .replace(/-/g, '') // Remove hyphen '-'
              .trim(), // Trim extra spaces
          }));
        setAudioOutputDevices(filteredAudioOutputDevices);
        console.log(filteredAudioOutputDevices);

        // Fetch and modify video devices (cameras)
        const cameraDevices = await AgoraRTC.getCameras();
        const filteredCameraDevices = cameraDevices
          .filter((device, index, self) =>
            index === self.findIndex((d) => d.groupId === device.groupId)
          )
          .map((device) => ({
            deviceId: device.deviceId,  // Retain deviceId for track creation
            ...device,
            label: device.label
              .replace(/default|communications/gi, '') // Remove 'default' and 'communications'
              .replace(/-/g, '') // Remove hyphen '-'
              .trim(), // Trim extra spaces
          }));
        setVideoDevices(filteredCameraDevices);
        console.log(filteredCameraDevices);

      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();

    // Device change event listener
    const handleDeviceChange = async () => {
      console.log("Device change detected, refetching devices...");
      await fetchDevices();
    };

    // Add event listener for device changes
    navigator.mediaDevices.ondevicechange = handleDeviceChange;

    return () => {
      // Remove the event listener when the component unmounts
      navigator.mediaDevices.ondevicechange = null;
    };
  }, []);




  const handleClickOutside = (event) => {
    if (optionsDialogRef.current && !optionsDialogRef.current.contains(event.target)) {
      setShowOptionsDialog(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handlePreviewCancel = () => setShowOptionsDialog(false);


  useEffect(() => {
    startVideoCall();

    return () => {
      cleanupResources();
    };
  }, []);






  useEffect(() => {
    if (joined && remoteUsers.length === 0 && callState === 'connected') {
      // Only show alert and end call if we were previously connected
      setShowAlert(true);
      setCallState('ending');
      const timer = setTimeout(() => {
        handleLeaveChannel();
      }, 5000); // 5 seconds delay

      return () => clearTimeout(timer);
    } else if (joined && remoteUsers.length > 0) {
      setCallState('connected');
    }
  }, [joined, remoteUsers, callState]);


  const handleUserJoined = (user) => {
    setRemoteUsers((prevUsers) => {
      if (!prevUsers.some(u => u.uid === user.uid)) {
        return [...prevUsers, user];
      }
      return prevUsers;
    });
    setCallState('connected');
  };

  const handleUserLeft = (user) => {
    setRemoteUsers((prevUsers) => prevUsers.filter((u) => u.uid !== user.uid));
    setRemoteCameraStates((prev) => {
      const newState = { ...prev };
      delete newState[user.uid];
      return newState;
    });
  };

  useEffect(() => {
    if (localVideoRef.current && currentVideoTrack) {
      if (isCameraEnabled) {
        try {
          currentVideoTrack.play(localVideoRef.current);
          setLocalVideoEnabled(true);
        } catch (error) {
          console.error("Failed to play local video track:", error);
          setLocalVideoEnabled(false);
        }
      } else {
        currentVideoTrack.stop();
        setLocalVideoEnabled(false);
      }
    }
  }, [currentVideoTrack, isCameraEnabled]);

  useEffect(() => {
    if (currentVideoTrack && clientRef.current && joined) {
      handleTrackPublishing(currentVideoTrack, isCameraEnabled);
    }
  }, [isCameraEnabled, currentVideoTrack, joined]);

  useEffect(() => {
    if (currentAudioTrack && clientRef.current && joined) {
      handleTrackPublishing(currentAudioTrack, isMicrophoneEnabled);
    }
  }, [isMicrophoneEnabled, currentAudioTrack, joined]);

  const handleTrackPublishing = async (track, isEnabled) => {
    if (!clientRef.current || !joined) return;

    try {
      if (isEnabled) {

        await track.setEnabled(true);
        await clientRef.current.publish(track);
        console.log(`${track.trackMediaType} track published`);
        console.log(track);
      } else {
        await clientRef.current.unpublish(track);
        await track.setEnabled(false);
        console.log(`${track.trackMediaType} track unpublished`);
      }
    } catch (error) {
      console.error(`Failed to ${isEnabled ? 'publish' : 'unpublish'} ${track.trackMediaType} track:`, error);
    }
  };

  const cleanupResources = async () => {
    console.log("Cleaning up resources...");
    if (clientRef.current) {
      await clientRef.current.leave();
      clientRef.current.removeAllListeners();
      clientRef.current = null;
    }
    setRemoteUsers([]);
    localUserIdRef.current = null;
    setJoined(false);
    console.log("Resources cleaned up");
  };

  async function startVideoCall() {
    if (isJoiningRef.current || localUserIdRef.current) {
      return;
    }

    isJoiningRef.current = true;

    try {
      await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/call_devices/${callingDeviceId}?CallerName=${encodeURIComponent(userInfo.name)}`
      );

      const tokenResponse = await fetch(
        `${process.env.REACT_APP_DEVICE_API_URL}/jwt/${callingDeviceId}`
      );
      const tokenData = await tokenResponse.json();
      const { token } = tokenData;

      const client = AgoraRTC.createClient({ codec: "vp8", mode: "rtc" });
      clientRef.current = client;

      client.on("user-published", handleUserPublished);
      client.on("user-unpublished", handleUserUnpublished);
      client.on("user-joined", handleUserJoined);
      client.on("user-left", handleUserLeft);
      await client.join(
        "c909117c706944bb8c9fdeeaaca70df6",
        callingAccountId,
        token,
        null
      );

      localUserIdRef.current = client.uid;
      setJoined(true);

      if (isCameraEnabled && currentVideoTrack) {
        await handleTrackPublishing(currentVideoTrack, true);
      }
      if (isMicrophoneEnabled && currentAudioTrack) {
        await handleTrackPublishing(currentAudioTrack, true);
      }
    } catch (error) {
      console.error("Failed to join channel", error);
    }

    isJoiningRef.current = false;
  }

  const handleUserPublished = async (user, mediaType) => {
    await clientRef.current.subscribe(user, mediaType);
    if (mediaType === "video") {
      setRemoteUsers((prevUsers) => {
        if (!prevUsers.some(u => u.uid === user.uid)) {
          return [...prevUsers, user];
        }
        return prevUsers;
      });
      setRemoteCameraStates(prev => ({ ...prev, [user.uid]: true }));
      if (remoteVideoRefs.current[user.uid]) {
        user.videoTrack.play(remoteVideoRefs.current[user.uid]);
      }
    }

    if (mediaType === "audio") {
      try {
        if (selectedAudioOutput) {
          await user.audioTrack.setPlaybackDevice(selectedAudioOutput);
          console.log("Playback device set successfully:", selectedAudioOutput);
        }
        user.audioTrack.play();
      } catch (error) {
        console.error("Error setting playback device:", error);
        // Fallback to default device
        user.audioTrack.play();
      }
    }
  };

  const handleUserUnpublished = (user, mediaType) => {
    if (mediaType === "video") {
      setRemoteCameraStates(prev => ({ ...prev, [user.uid]: false }));
      if (user.videoTrack) {
        user.videoTrack.stop();
      }
    }
    if (mediaType === "audio") {
      if (user.audioTrack) {
        user.audioTrack.stop();
      }
    }
  };

  const handleLeaveChannel = async () => {
    console.log("Leaving channel...");
    try {
      await cleanupResources();
      onClose();
    } catch (error) {
      console.error("Failed to leave channel", error);
    }
  };

  const toggleCamera = async () => {
    if (currentVideoTrack) {
      const newState = !isCameraEnabled;
      setIsCameraEnabled(newState);

      try {
        await handleTrackPublishing(currentVideoTrack, newState);

        if (newState) {
          if (localVideoRef.current) {
            currentVideoTrack.play(localVideoRef.current);
            setLocalVideoEnabled(true);
          } else {
            console.warn("Local video container is not ready");
            setLocalVideoEnabled(false);
          }
        } else {
          currentVideoTrack.stop();
          setLocalVideoEnabled(false);
        }
      } catch (error) {
        console.error("Failed to toggle camera:", error);
        setIsCameraEnabled(!newState); // Revert the state if there's an error
        setLocalVideoEnabled(!newState);
      }
    }
  };

  const toggleMicrophone = async () => {
    if (currentAudioTrack) {
      const newState = !isMicrophoneEnabled;
      setIsMicrophoneEnabled(newState);
      await handleTrackPublishing(currentAudioTrack, newState);
    }
  };



  const renderVideoContainer = (videoTrack, label, isLocal = false) => (
    <div style={{
      width: isLocal ? "25%" : "100%",
      height: isLocal ? "25%" : "100%",
      position: isLocal ? "absolute" : "relative",
      top: isLocal ? "20px" : "auto",
      right: isLocal ? "20px" : "auto",
      zIndex: isLocal ? 2 : 1,
      borderRadius: "12px",
      overflow: "hidden",
      boxShadow: isLocal ? "0 4px 12px rgba(0,0,0,0.2)" : "none",
    }}>
      <div
        style={{
          backgroundColor: "#1a1a1a",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "hidden",
          borderRadius: "12px",
        }}
      >
        <p
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "white",
            fontSize: isLocal ? "12px" : "16px",
            margin: 0,
            zIndex: 3,
            backgroundColor: "rgba(0,0,0,0.5)",
            padding: "5px 10px",
            borderRadius: "20px",
          }}
        >
          {label}
        </p>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          {isLocal ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                display: localVideoEnabled ? "block" : "none",
              }}
              ref={localVideoRef}
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              ref={(ref) => {
                if (ref && videoTrack) {
                  remoteVideoRefs.current[videoTrack.getUserId()] = ref;
                  videoTrack.play(ref);
                }
              }}
            />
          )}
          {(!isLocal && !remoteCameraStates[videoTrack?.getUserId()]) || (isLocal && !localVideoEnabled) ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#2c3e50",
                color: "white",
                fontSize: isLocal ? "14px" : "24px",
                fontWeight: "bold",
              }}
            >
              Camera Off
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );

  const buttonStyle = (enabled) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    margin: "0 10px",
    borderRadius: "50%",
    border: "none",
    cursor: "pointer",
    transition: "all 0.3s ease",
    backgroundColor: enabled ? "#1976D2" : "#1976D2",
    boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
    color: "white",
  });



  return (
    <div style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#000",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <div style={{
        position: "relative",
        width: "100%",
        height: "100%",
        maxWidth: `${16 / 9 * 100}vh`,
        maxHeight: `${9 / 16 * 100}vw`,
        backgroundColor: "#000",
        overflow: "hidden",
      }}>
        {remoteUsers.length > 0 ? (
          remoteUsers.map(user => renderVideoContainer(user.videoTrack, `${user.uid}`))
        ) : (
          <div style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "24px",
          }}>
            {callState === 'waiting' ? "Waiting for target user to join..." : "The other participant has left the call."}
          </div>
        )}
        {renderVideoContainer(currentVideoTrack, `${userInfo.name} (You)`, true)}
        <div style={{
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          borderRadius: "30px",
          zIndex: 3,
        }}>
          <button onClick={toggleMicrophone} style={buttonStyle(isMicrophoneEnabled)} aria-label="Toggle Microphone">
            {isMicrophoneEnabled ? <KeyboardVoiceRounded /> : <MicOffRounded />}
          </button>
          <button onClick={toggleCamera} style={buttonStyle(isCameraEnabled)} aria-label="Toggle Camera">
            {isCameraEnabled ? <VideocamRounded /> : <VideocamOffRounded />}
          </button>
          <button onClick={() => { setShowOptionsDialog(true) }} style={{ ...buttonStyle(true), transform: 'rotate(90deg)', }} aria-label="More Options">
            <MoreVert />
          </button>
          <button onClick={handleLeaveChannel} style={{
            ...buttonStyle(true),
            backgroundColor: "rgba(211, 47, 47, 0.8)",
          }} aria-label="End Call">
            <CallEndRounded />
          </button>
        </div>
      </div>
      {showOptionsDialog && (
        <div
          ref={optionsDialogRef}
          style={{
            position: "absolute",
            bottom: "110px", // Adjust this value to control the gap between the icons and the dialog
            left: "calc(50% + 35px)",
            transform: "translateX(-50%)",
            backgroundColor: "#f0f0f0",
            padding: "15px",
            borderRadius: "8px",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            zIndex: 4,
            maxWidth: "300px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

            <div style={{ width: "100%", maxWidth: "350px", marginBottom: "10px" }}>
              <label htmlFor="audio-inputselect" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Audio Input</label>
              <select
                id="audio-input-select"
                value={selectedAudioInput}
                onChange={(e) => handleAudioInputChange(e)}
                style={{ width: "100%", padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "14px" }}
              >
                {/* <option value="">{selectedAudioInput ? selectedAudioInput : `System Default - ${audioInputDevices[0]?.label || "No Device"}`}</option> */}
                <option value="">
                  {selectedAudioInput
                    ? `System Default - ${audioInputDevices.find(device => device.deviceId === selectedAudioInput)?.label || "No Device"}`
                    : `System Default - ${audioInputDevices[0]?.label || "No Device"}`}
                </option>
                {audioInputDevices.map(device => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || "Unnamed Audio Input"}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ width: "100%", maxWidth: "350px", marginBottom: "10px" }}>
              <label htmlFor="audio-output-select" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Audio Output</label>
              <select
                id="audio-output-select"
                value={selectedAudioOutput}
                onChange={(e) => handleAudioOutputChange(e, setSelectedAudioOutput)}
                style={{ width: "100%", padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "14px" }}
              >
                {/* <option value="">{selectedAudioOutput ? selectedAudioOutput : `System Default - ${audioOutputDevices[0]?.label || "No Device"}`}</option> */}
                <option value="">
                  {selectedAudioOutput
                    ? `System Default - ${audioOutputDevices.find(device => device.deviceId === selectedAudioOutput)?.label || "No Device"}`
                    : `System Default - ${audioOutputDevices[0]?.label || "No Device"}`}
                </option>
                {audioOutputDevices.map(device => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || "Unnamed Audio Output"}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: "100%", maxWidth: "350px" }}>
              <label htmlFor="camera-select" style={{ display: "block", marginBottom: "5px", fontWeight: "bold" }}>Camera</label>
              <select
                id="camera-select"
                value={selectedVideo}
                onChange={(e) => handleCameraChange(e)}
                style={{ width: "100%", padding: "8px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "14px" }}
              >
                {/* <option value=""> {selectedVideo ? selectedVideo : `System Default - ${videoDevices[0]?.label || "No Device"}`}</option> */}
                <option value="">
                  {selectedVideo
                    ? `System Default - ${videoDevices.find(device => device.deviceId === selectedVideo)?.label || "No Device"}`
                    : `System Default - ${videoDevices[0]?.label || "No Device"}`}
                </option>
                {videoDevices.map(device => (
                  <option key={device.deviceId} value={device.deviceId}>
                    {device.label || "Unnamed Camera"}
                  </option>
                ))}
              </select>
            </div>
          </div>


          <div style={{
            position: "absolute",
            bottom: "-10px", // Position the triangle at the bottom of the dialog box
            left: "50%",
            transform: "translateX(-50%)",
            width: 0,
            height: 0,
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderTop: "10px solid #f0f0f0", // Triangle color matching the dialog background
          }}
          ></div>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
            <Button onClick={() => setShowOptionsDialog(false)} color="secondary">
              Close
            </Button>
          </div>
        </div>
      )}
      <Dialog
        open={showAlert}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Call Ending</DialogTitle>
        <DialogContent>
          <Typography>
            The other participant has left the call. The video call will close in 5 seconds.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLeaveChannel} color="primary" autoFocus>
            Close Now
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );




}

export default AgoraVideoCall;